body {
  background-color: #1a1a21;
  text-align: right;
  padding: 0px;
  padding-bottom: 50px;
  max-width: 100% !important;
}

* {
  margin: 0;
  text-decoration: none;
}

a:link {
  text-decoration: none !important;
}

.pic3_cont {
  width: 1100px;
}

.main_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.second_container {
  width: 1300px;
  display: flex;
  flex-direction: column;
}

.first_header {
  margin-top: 70px;
  align-self: center;
  margin-bottom: 100px;
}

.general_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 100px;
}

p {
  font-size: 25px;
  line-height: 40px;
  color: #e3e2c8;
}

.tel {
  color: #cfc525;
  font-size: 45px;
  font-weight: 800;
}

.tel_container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-self: flex-end;
  margin-right: 40px;
  margin-top: 40px;
  align-items: center;
}

.top {
  margin-top: 80px;
}
.bottom {
  margin-bottom: 30px;
}

.black_seperator {
  width: 100%;
  background-color: black;
  height: 1px;
  margin-top: 60px;
}

.max_width {
  max-width: 600px;
  align-self: flex-end;
}

.youtube_container {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  width: 728px;
  height: 409px;
  align-self: center;
}

.yellow_seperator_big {
  background-color: #efbb48;
  height: 16px;
  width: 550px;
  margin-bottom: 70px;
}

.yellow_seperator_small {
  background-color: #efbb48;
  height: 2px;
  margin-bottom: 4px;
  width: 550px;
}

.pic_container {
  display: flex;
  flex-direction: column;
}

.img_cont_1 {
  width: 750px;
  align-self: center;
}

.mehiron_cont {
  width: 600px;
}

@media only screen and (max-width: 700px) {
  body {
    overflow-x: hidden !important;
    max-width: none !important;
    min-width: 0 !important;
    width: 100% !important;
  }
  .img_cont_1 {
    width: 450px;
  }
  .second_container {
    width: 100%;
    align-items: center;
    align-self: center;
  }
  .main_container {
    align-self: center;
    overflow-x: hidden !important;
    width: 100% !important;
  }
  .general_container {
    flex-direction: column;
    align-items: center;
  }
  .tel {
    font-size: 26px;
    min-width: 100% !important;
  }

  p {
    max-width: 80%;
    text-align: center;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    align-self: center;
    display: flex;
    width: 80%;
  }

  .tel_container {
    flex-direction: column;
    align-items: center;
    align-self: center;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .text {
    order: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .pic_container {
    order: 2;
    align-self: center;
  }
  .general_container {
    flex-direction: column;
  }

  .mehiron_cont {
    width: 100%;
  }

  .max_width {
    max-width: none;
    align-self: center;
    min-width: 100%;
  }

  .image {
    order: 1;
    align-self: center;
    margin-bottom: 40px;
  }

  .yellow_seperator_small {
    order: 2;
    width: 90%;
    display: none;
  }

  .yellow_seperator_big {
    order: 3;
    margin-bottom: 0px;
    width: 90%;
    display: none;
  }

  .youtube_container {
    width: 354px;
    height: 205px;
    align-self: center;
  }
  .none {
    display: none;
  }

  .pic3_cont {
    width: 80%;
  }

  .text {
    max-width: 90%;
    width: 80%;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
  }
}
